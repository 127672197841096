"use client";
import { Adb, AdbDaemonTransport } from "@yume-chan/adb";
import { AdbScrcpyClient } from "@yume-chan/adb-scrcpy";
import { ConsumableWritableStream, DecodeUtf8Stream, InspectStream, ReadableStream, WrapConsumableStream, WrapReadableStream, WrapWritableStream, WritableStreamDefaultController, pipeFrom } from "@yume-chan/stream-extra";
import { AdbPacketData, AdbPacketInit } from "@yume-chan/adb";
import { Consumable, ReadableWritablePair } from "@yume-chan/stream-extra";
import { useState, useEffect, useCallback, useMemo } from 'react';
import { AdbDaemonWebUsbConnection, AdbDaemonWebUsbDevice, AdbDaemonWebUsbDeviceManager, AdbDaemonWebUsbDeviceWatcher } from "@yume-chan/adb-daemon-webusb";
import { AdbDaemonDevice } from '@yume-chan/adb';
import AdbWebCredentialStore from "@yume-chan/adb-credential-web";
import { AdbServerClient } from "@yume-chan/adb";
// import { useAdbContext } from "@/contexts/adbContext";
import { Icon } from "@iconify/react/dist/iconify.js";
import { ReadableStreamDefaultReader } from "stream/web";
import { GLOBAL_STATE } from "./global";
import Scrcpy from "./scrcpy";
import { observer } from "mobx-react-lite";
import { useBoolean } from "usehooks-ts";

function Mirror() {
  // const {
  //   adb,
  //   device,
  //   setAdb,
  //   setDevice,
  //   appendPacketLog,
  //   packetLog,
  //   showErrorDialog,
  //   errorDialogMessage,
  //   errorDialogVisible,
  // } = useAdbContext();

  const [selectedDevice, setSelectedDevice] = useState<AdbDaemonWebUsbDevice | undefined>(undefined);
  const [connecting, setConnecting] = useState(false);
  // const [Manager, setManager] = useState<AdbDaemonWebUsbDeviceManager>();
  const [USBDevices, setUSBDevices] = useState<AdbDaemonWebUsbDevice[]>([]);
  const [credentialStore, setCredentialStore] = useState<AdbWebCredentialStore>(new AdbWebCredentialStore());
  const [usbSupported, setUsbSupported] = useState(false);

  const updateUsbDeviceList = useCallback(async () => {
    const devices: AdbDaemonWebUsbDevice[] =
      await AdbDaemonWebUsbDeviceManager.BROWSER!.getDevices();
    setUSBDevices(devices);
    return devices;
  }, []);

  useEffect(() => {
    const supported = !!AdbDaemonWebUsbDeviceManager.BROWSER;
    setUsbSupported(supported);

    if (!supported) {
      alert("Your browser does not support WebUSB standard, which is required for this site to work.\n\nLatest version of Google Chrome, Microsoft Edge, or other Chromium-based browsers are required.");
      return;
    }
    updateUsbDeviceList();
    const watcher = new AdbDaemonWebUsbDeviceWatcher(async (serial?: string) => {
      const list = await updateUsbDeviceList();

      if (serial) {
        setUSBDevices([list.find((device) => device.serial === serial) as AdbDaemonWebUsbDevice]);
        return;
      }
    }, globalThis.navigator.usb);

    return () => watcher.dispose();
  }, []);


  const requestPermission = async () => {
    if (navigator) {
      const device: USBDevice = await navigator.usb.requestDevice({
        filters: [
          {
            classCode: 0xff,
            subclassCode: 0x42,
            protocolCode: 1,
          },
        ],
      });
      console.log("devices", device);


      getDevices();
    }
  };

  const getDevices = async () => {
    if (AdbDaemonWebUsbDeviceManager.BROWSER) {
      const devices: AdbDaemonWebUsbDevice[] = await AdbDaemonWebUsbDeviceManager.BROWSER.getDevices();
      console.log("devices", devices);
      if (!devices.length) {
        alert("No device connected");
        return;
      }

      setUSBDevices([devices[0]]);
    }
  };

  const connect = async (device: AdbDaemonWebUsbDevice) => {
    console.log("device", device.serial);

    if (!device || !credentialStore) return;

    setConnecting(true);

    let readable: ReadableStream<AdbPacketData>;
    let writable: WritableStream<Consumable<AdbPacketInit>>;
    let streams: ReadableWritablePair<AdbPacketData, Consumable<AdbPacketInit>>;

    try {
      streams = await device.connect();

      readable = streams.readable.pipeThrough(new InspectStream((packet) => {
        GLOBAL_STATE.appendLog("in", packet);
      }));
      writable = pipeFrom(
        streams.writable,
        new InspectStream((packet: Consumable<AdbPacketInit>) => {
          GLOBAL_STATE.appendLog("out", packet.value);
        })
      );
    } catch (e: any) {
      GLOBAL_STATE.showErrorDialog(e);
      setConnecting(false);
      return;
    }

    async function dispose() {
      // Adb won't close the streams,
      // so manually close them.
      try {
        readable.cancel();
      } catch { }
      try {
        await writable.close();
      } catch { }
      GLOBAL_STATE.setDevice(undefined, undefined);
    }

    // credentialStore.generateKey().then((key) => {
    //   console.log("key", key);
    // }
    // ).catch((e) => {
    //   console.error("Error generating key", e);
    // });

    try {
      const transport = await AdbDaemonTransport.authenticate({
        serial: device.serial,
        // @ts-ignore
        connection: {
          readable: readable,
          // @ts-ignore
          writable: writable,
        },
        credentialStore: credentialStore,
      });

      const client = new Adb(transport);

      client.disconnected.then(
        async () => {
          await dispose();
        },
        async (e) => {
          GLOBAL_STATE.showErrorDialog(e);
          await dispose();
        }
      );

      GLOBAL_STATE.setDevice(USBDevices[0], client);
    } catch (e) {
      console.error("Error authenticating", e);
      await dispose();
    } finally {
      setConnecting(false);
    }
  }

  const disconnect = useCallback(async () => {
    try {
      await GLOBAL_STATE.adb!.close();
    } catch (e: any) {
      GLOBAL_STATE.showErrorDialog(e);
    }
  }, []);

  const deviceList = useMemo(() => {
    return ([] as AdbDaemonWebUsbDevice[]).concat(USBDevices);
  }, [USBDevices]);

  const deviceOptions = useMemo(() => {
    return deviceList.map((device) => (
      {
        key: device.serial,
        text: `${device.serial} - ${device.name ? `(${device.name})` : ''}`,
        device: device,
      }
    ));
  }, [deviceList]);

  useEffect(() => {
    setSelectedDevice((old) => {
      if (old && !deviceList.find((d) => d.serial === old.serial)) {
        const current = deviceList.find((d) => d.serial === old.serial);
        if (current) {
          return current;
        }
      }
      return undefined;
    });

  }, [deviceList]);

  const DeviceInfo = useMemo(() => {
    if (!GLOBAL_STATE.adb) {
      return undefined;
    }

    return {
      productName: GLOBAL_STATE.adb.banner.product,
      modelName: GLOBAL_STATE.adb.banner.model,
      deviceName: GLOBAL_STATE.adb.banner.device,
      // battery: await adb.subprocess.spawnAndWaitLegacy("dumpsys battery").then((output) => {
      //   return output;
      // }),
    }
  }, [GLOBAL_STATE.adb]);

  const [BatteryLevel, setBatteryLevel] = useState<any>(undefined);

  const getBatteryInformation = useMemo(() => {
    if (!GLOBAL_STATE.adb) {
      return undefined;
    }

    return GLOBAL_STATE.adb.subprocess.spawnAndWaitLegacy("dumpsys battery").then((output) => {
      // use regex to parse output
      const level = output.match(/level: (\d+)/);
      const scale = output.match(/scale: (\d+)/);
      const status = output.match(/status: (\w+)/);
      const health = output.match(/health: (\w+)/);
      const temperature = output.match(/temperature: (\d+)/);

      const obj = {
        level: level ? parseInt(level[1]) : undefined,
        scale: scale ? parseInt(scale[1]) : undefined,
        status: status ? status[1] : undefined,
        health: health ? health[1] : undefined,
        temperature: temperature ? temperature[1] : undefined,
      }

      setBatteryLevel(obj);
    });
  }, [GLOBAL_STATE.adb]);


  // const getWifiStatus = useMemo(() => {
  //   if (!adb) {
  //     return undefined;
  //   }

  //   return adb.subprocess.spawnAndWaitLegacy(
  //     [
  //       "dumpsys",
  //       "wifi",
  //     ]
  //   ).then((output) => {
  //     // console.log("output", output)
  //     // use regex to parse output
  //     const wifi = output.match(/Wi-Fi is (\w+)/);
  //     const airplane = output.match(/Airplane mode: (\w+)/);
  //     const wifiState = output.match(/Wi-Fi is (\w+)/);
  //     const wifiAp = output.match(/SSID: (.+)/);
  //     const wifiMac = output.match(/MAC: (.+)/);
  //     const wifiIp = output.match(/IP: (.+)/);
  //     const wifiSpeed = output.match(/Link speed: (\d+)/);
  //     const wifiFrequency = output.match(/Frequency: (\d+)/);
  //     const wifiSignal = output.match(/Signal level: (\d+)/);
  //     const wifiTx = output.match(/Tx Link speed: (\d+)/);
  //     const wifiRx = output.match(/Rx Link speed: (\d+)/);

  //     const obj = {
  //       wifi: wifi ? wifi[1] : undefined,
  //       airplane: airplane ? airplane[1] : undefined,
  //       wifiState: wifiState ? wifiState[1] : undefined,
  //       wifiAp: wifiAp ? wifiAp[1] : undefined,
  //       wifiMac: wifiMac ? wifiMac[1] : undefined,
  //       wifiIp: wifiIp ? wifiIp[1] : undefined,
  //       wifiSpeed: wifiSpeed ? wifiSpeed[1] : undefined,
  //       wifiFrequency: wifiFrequency ? wifiFrequency[1] : undefined,
  //       wifiSignal: wifiSignal ? wifiSignal[1] : undefined,
  //       wifiTx: wifiTx ? wifiTx[1] : undefined,
  //       wifiRx: wifiRx ? wifiRx[1] : undefined,
  //     }

  //     // console.log("wifi", obj)
  //   });
  // }, [adb]);

  // const socketConnect = useCallback(async () => {
  //   if (!adb) {
  //     return;
  //   }

  //   for (let i = 0; i < 10; i++) {
  //     try {
  //       return await adb.createSocket("tcp:5555");
  //     }
  //     catch (e) {
  //       console.log("Connection failed, retrying", e);
  //       await new Promise((resolve) => setTimeout(resolve, 100));
  //     }
  //   }
  // }, [adb]);

  // const writeInt32 = async (w: WritableStreamDefaultWriter<Consumable<Uint8Array>>, r: number) => {
  //   const n = new Uint8Array([r & 255, r >> 8 & 255, r >> 16 & 255, r >> 24 & 255]);
  //   await ConsumableWritableStream.write(w, n)
  // };

  const BatteryLevelComponent = () => {
    if (!BatteryLevel) {
      return null;
    }

    // visualize battery level with level/scale 
    return (
      <div className="flex items-center space-x-1 pt-1">
        <div className="flex w-10 h-5 items-center relative">
          <div className="border-gray-500 border-2 rounded-[3px] flex-1 h-full p-[1px]">
            <div className="bg-green-500 h-full rounded-[2px]" style={{ width: `${(BatteryLevel.level / BatteryLevel.scale) * 100}%` }}></div>
          </div>
          <div className="bg-gray-500 rounded-r-md w-1 h-1/2"></div>
        </div>
        <div className="text-sm font-medium text-gray-800 whitespace-wrap">
          {BatteryLevel.level / BatteryLevel.scale * 100}%
        </div>
      </div>
    );
  }

  const { value: isMirrorOpen, toggle: toggleIsMirrorOpen } = useBoolean(false);

  return (
    <div className="w-full items-center justify-between text-sm flex flex-col space-y-4 lg:flex-row lg:gap-x-4 lg:items-start lg:justify-between lg:space-y-0">
      <div className="items-start justify-between text-sm flex flex-col bg-white rounded-2xl px-8 py-4 shadow-xl w-full">
        <div className="text-lg font-medium text-gray-500 select-none cursor-pointer items-center flex w-full justify-between" onClick={() => { toggleIsMirrorOpen() }}>
          Mirror Your Screen
          <Icon icon="ep:arrow-up-bold" className={`${isMirrorOpen ? "rotate-180" : "rotate-0"} transform duration-300`} />
        </div>
        <div className={`${isMirrorOpen ? "max-h-[600px]" : "max-h-0"} overflow-hidden transition-all w-full duration-300`}>
          <div>
            {usbSupported ?
              <div className="flex space-x-2 items-center my-4">
                <Icon icon="feather:check-circle" className="text-green-500 w-4 h-4" /><div className="text-xs">USB connections are <span className="font-semibold text-green-500">supported</span> by your browser</div>
              </div> :
              <div className="flex space-x-4 items-center w-full bg-red-100 p-2 pl-4 rounded-md">
                <Icon icon="feather:x-circle" className="text-red-500 w-8 h-8" />
                <div className="flex flex-col">
                  <div className="items-center text-base font-semibold">USB connections are <span className="font-semibold text-red-500">not supported</span> by your browser</div>
                  <div className="text-sm">Latest version of Google Chrome, Microsoft Edge, or other Chromium-based browsers are required for Screen Mirroring.</div>
                </div>
              </div>
            }
            {usbSupported && (
              <div className="flex flex-col space-y-2 w-full">
                {/* <Icon icon="bi:headset-vr" className="w-8 h-8" /><Icon icon="bi:usb-plug-fill" className="w-6 h-6" /> */}
                <div className="flex space-x-4 items-center">
                  <div className="font-semibold text-gray-500">1.</div>
                  <div className="flex items-center justify-center w-8 h-8"><Icon icon="bi:headset-vr" className="w-8 h-8 block" /></div>
                  <div className="">Power on your headset and connect the small end of the USB cable to the headset</div>
                  <div className="flex items-center justify-center w-8 h-8 p-1"><Icon icon="bi:usb-micro" className="w-8 h-8" /></div>
                </div>
                <div className="flex space-x-4 items-center">
                  <div className="font-semibold text-gray-500">2.</div>
                  <div className="flex items-center justify-center w-8 h-8"><Icon icon="bi:usb-plug-fill" className="w-8 h-8" /></div>
                  <div className="">Connect the other end of the USB cable to your computer, laptop, or tablet</div>
                  <div className="flex items-center justify-center w-8 h-8"><Icon icon="bi:usb" className="w-8 h-8" /></div>
                </div>

                <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row justify-between md:items-center w-full">
                  <div className="flex space-x-4 items-center">
                    <div className="font-semibold text-gray-500">3.</div><Icon icon="ph:cursor-click-fill" className="w-8 h-8" /><div className="">Click the <span className="font-medium">Add Headset</span> button and select the Headset (it may start with &quot;SX...&quot;) and click the <span className="font-medium">Connect</span> button</div>
                  </div>
                  <button className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 disabled:opacity-50" onClick={requestPermission}>Add Headset</button>
                </div>
                <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row justify-between md:items-center w-full">
                  <div className="flex space-x-4 items-center">
                    <div className="font-semibold text-gray-500">4.</div><Icon icon="ph:cursor-click-fill" className="w-8 h-8" /><div className="">The Headset should be listed below. Click the <span className="font-medium">Connect</span> button to start Screen Mirroring</div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="text-lg font-medium text-gray-500">
                    Connected Devices
                  </div>
                  {/* <div>{adb && JSON.stringify(BatteryLevel)}</div> */}
                  <div className="flex flex-col w-full space-y-2">
                    {deviceOptions.map((d) => (
                      <div key={d.key} className={`flex justify-between items-center w-full ${selectedDevice === d.device ? 'bg-gray-500' : ''}`} onClick={() => setSelectedDevice(d.device)}>
                        <label>{d.text}</label>
                        <div>{GLOBAL_STATE.adb ? 'Connected' : ''}</div>
                        <div className="flex justify-center items-center">
                          <>
                            {selectedDevice === d.device && GLOBAL_STATE.adb?.serial === d.device.serial ?
                              <button
                                className="px-4 py-2 mt-4 text-white bg-red-500 rounded-md hover:bg-red-600 w-full disabled:opacity-50"
                                onClick={disconnect}
                              >Disconnect</button> :
                              <button
                                disabled={selectedDevice !== d.device || connecting}
                                className="px-4 py-2 mt-4 text-white bg-blue-500 rounded-md hover:bg-blue-600 w-full disabled:opacity-50"
                                onClick={() => connect(d.device)}
                              >Connect</button>
                            }
                          </>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="flex justify-between">
                  <div className="flex flex-col space-y-0">
                    <div className="text-xs font-medium text-gray-500">
                      Device Model
                    </div>
                    {DeviceInfo && (
                      <div className="text-xl font-medium text-gray-800 whitespace-wrap w-full">{DeviceInfo.modelName}</div>
                    )}
                  </div>
                  <div className="flex flex-col space-y-0">
                    <div className="text-xs font-medium text-gray-500 text-right">
                      Battery Level
                    </div>
                    <div className="text-lg font-medium text-gray-800 whitespace-wrap w-full"><BatteryLevelComponent /></div>
                  </div>
                </div>

                <div className="relative w-full">

                  <Scrcpy />
                </div>

                {/* <div className="flex flex-col">
              {connecting && <div>Connecting...</div>}
              <div className="text-lg font-medium text-gray-500">
                Packet Log
              </div>
              <div className="text-xs font-medium text-gray-800 whitespace-wrap w-full h-48 overflow-y-auto overflow-x-hidden">
                {GLOBAL_STATE.logs.map((packet, index) => (
                  <div key={index} className="flex space-x-2">
                    <div>{packet.direction}</div>
                    <div>{packet.command}</div>
                  </div>
                ))}

              </div>

            </div> */}

              </div>
            )}
          </div>
        </div>
      </div >
    </div>

  );
}


export default observer(Mirror);